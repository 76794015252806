import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { track } from 'utils/analytics';

interface IntroductionProps {}

const Introduction: FC<IntroductionProps> = () => {
  useEffect(() => {
    track('Introduction Viewed');
    navigate('/pdfs/introduction.pdf');
  }, []);

  return <></>;
};

export default Introduction;
